import { trigger, transition, style, animate } from '@angular/animations';

export const slideFromTop = trigger('slideFromTop', [
  transition('void => *', [
    style({ opacity: 0, height: 0, padding: 0, margin: 0, overflow: 'hidden' }),
    animate(
      '300ms 0s cubic-bezier(.51,.01,.74,.99)',
      style({ opacity: 1, height: '*', padding: '*', margin: '*' })
    ),
  ]),
  transition('* => void', [
    style({ overflow: 'hidden' }),
    animate(
      '300ms 0s cubic-bezier(.51,.01,.74,.99)',
      style({ opacity: 0, height: 0, padding: 0, margin: 0 })
    ),
  ]),
]);

export const slideFromLeft = trigger('slideFromLeft', [
  transition(':enter', [
    style({ opacity: 0, width: 0 }),
    animate('300ms ease-out', style({ opacity: 1, width: '*' })),
  ]),
  transition(':leave', [
    animate('300ms ease-in', style({ opacity: 0, width: 0 })),
  ]),
]);
