/**
 * Checks if the provided array is empty.
 * @param array - The array to check.
 * @returns `true` if the array is empty, `false` otherwise.
 */
export function Empty(array: Array<any>): boolean {
  return array.length === 0;
}

/**
 * Determines the type of items in the provided array.
 * @param array - The array to check.
 * @returns The type of the items in the array, or 'unknown' if the array is
 * empty or the items are of an unknown type.
 */
export function TypeOfItems(array: Array<any>): string {
  if (!array.length) {
    return 'unknown';
  }

  const firstItem = array[0];

  if (typeof firstItem === 'string') {
    return 'string';
  } else if (typeof firstItem === 'number') {
    return 'number';
  } else if (typeof firstItem === 'object' && firstItem !== null) {
    return 'object';
  } else {
    return 'unknown';
  }
}
